<template>
    <div class="row g-5 g-xl-8" v-for="(module, moduleIndex) in modules" :key="moduleIndex" :class="{'mt-6': moduleIndex != 0 }">
        <h3 class="text-dark">{{ module.name }}</h3>
        <el-divider class="mt-3 mb-4"></el-divider>
        <div class="col-xl-3" v-for="(item, index) in module.items" v-bind:key="index">
            <div class="card module-card">
                <!--begin::Header-->
                <div class="card-header justify-content-center card-header">
                    <h3 class="card-title text-dark">{{ item.name }}</h3>
                </div>
                <div class="card-body text-center">
                    <router-link :to="getRedirectUrl(item)">
                        <span class="svg-icon svg-icon-4x svg-icon-dark d-block my-2">
                            <inline-svg src="/media/icons/duotune/general/gen055.svg" />
                        </span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    data() {
        return {
            redirectUrlPattern: '/module/%s',
            customRedirect: {
                OUT_QUESTION: "/module/out-question/category",
                VOICE_RECORDING: "/module/voice-record/category",
                EXAM: "/module/exam-management",
                ZOOM: "/module/zoom/meeting",
                SOURCE: "/module/source/group",
                FEATURED_PRODUCT: "/module/featured-product/group",
                QUESTION_SOLUTION: "/module/question-solution/category",
            }
        }
    },
    computed: {
        modules() {
            return this.$store.state.module.table.data;
        }
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t("menu.modules"), [this.$t("menu.moduleManagement")]);
        this.$store.dispatch("module/get",{
            filterData: {
                is_support_ui: true
            }
        });
    },
    methods: {
        getRedirectUrl(item) {
            if(this.customRedirect[item.code]){
                return this.customRedirect[item.code];
            }

            return this.sprintf(this.redirectUrlPattern, [item.code.toLowerCase().replaceAll('_', '-')]);
        }
    }
}
</script>

<style>
    .module-card .card-header{
        min-height: 65px;
    }
</style>